export const columns = [
  {
    title: "Số kỳ",
    key: "incId",
    slots: { customRender: "incId" },
    align: "center",
  },
  {
    title: "Trò chơi",
    key: "game",
    dataIndex: "game",
  },
  {
    title: "Loại cược",
    key: "zone",
    slots: { customRender: "zone" },
    align: "center",
  },
  {
    title: "Id người chơi",
    key: "playerIncId",
    slots: { customRender: "playerIncId" },
    align: "center",
  },
  {
    title: "Đặt cược",
    key: "betPlace",
    slots: { customRender: "betPlace" },
    align: "center",
  },
  {
    title: "Tiền cược",
    key: "amountBet",
    dataIndex: "amountBet",
  },
  {
    title: "Tiền thắng",
    key: "amountWinnings",
    dataIndex: "amountWinnings",
  },
  {
    title: "Trạng thái",
    key: "status",
    slots: { customRender: "status" },
  },
  {
    title: "Thời gian",
    key: "time",
    dataIndex: "time",
  },
  {
    title: "",
    key: "action",
    slots: {
      customRender: "action",
    },
  },
];
