<template>
  <div>
    <Input
      v-model:value="value"
      :pattern="pattern"
      :placeholder="placeholder"
      :type="type"
      :disabled="disabled"
      @input="validInput"
      @mousewheel.prevent
    />
  </div>
</template>

<script setup>
import { Input } from "ant-design-vue";
import { ref, watch } from "vue";
const props = defineProps({
  size: {
    type: String,
    default: "",
  },
  modelValue: {
    type: [String, Number],
  },
  type: {
    type: String,
    default: "text",
  },
  placeholder: {
    type: String,
  },
  disabled: {
    type: Boolean,
  },
  pattern: {
    type: String,
  },
});

const emit = defineEmits(["update:modelValue"]);

const value = ref(props.modelValue);

const isDigit = /^\d+$/;

const validInput = (e) => {
  if (props.type === "number") {
    if (e.inputType === "insertFromPaste") {
      if (!isDigit.test(e.target.value)) {
        value.value = "";
      }
    } else if (!isDigit.test(value.value) && !(value.value === "")) {
      value.value = props.modelValue;
      return;
    }
  }
  if (value.value.startsWith("0")) {
    value.value = value.value.replace(/^0+(?!$)/, "");
  }
  emit("update:modelValue", value.value);
};

watch(props, () => {
  value.value = props.modelValue;
});
</script>
