<template>
  <div>
    <h1 class="p-16 text-20 font-bold bg-white mb-16">Danh sách nạp tiền</h1>
    <div class="p-32 pt-0">
      <div class="flex">
        <form @submit.prevent class="flex">
          <div class="flex gap-4 items-center mr-8">
            <span>ID: </span>
            <Input v-model="payload.playerIncId" :style="{ width: '165px' }" />
          </div>
          <!-- <div class="flex gap-4 items-center">
          <span>Trạng thái: </span>
          <Select
            v-model="status"
            :options="STATUSES"
            :style="{ width: '165px' }"
          />
        </div> -->
          <Button @click="search" htmlType="submit" type="primary">
            <SearchOutlined />
            Tìm kiếm
          </Button>
        </form>
        <AddDeposit
          v-if="$can('edit', 'balance')"
          @mutated="
            getDeposits({ page: currentPage, playerIncId: payload.playerIncId })
          "
        />
      </div>
      <DepositTable
        :dataSource="deposits"
        class="my-32"
        @mutated="
          getDeposits({ page: currentPage, playerIncId: payload.playerIncId })
        "
      />
      <div class="mt-16 fixed bottom-16 right-32">
        <Pagination
          v-model:current="currentPage"
          :total="pagination.totalItems"
          :pageSize="pagination.perPage"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import Input from "@/components/Input.vue";
import Select from "@/components/Select.vue";
import Button from "@/components/Button.vue";
import AddDeposit from "./components/AddDeposit.vue";
import DepositTable from "./components/DepositTable";
import { Pagination, message } from "ant-design-vue";
import { SearchOutlined } from "@ant-design/icons-vue";
import { ref, watch } from "vue";
import { STATUSES } from "@/common";
import useDeposits from "@/use/deposits";
// const status = ref();
const { deposits, pagination, getDeposits } = useDeposits();
const currentPage = ref(1);
const payload = ref({
  playerIncId: "",
});
getDeposits({ page: currentPage.value, ...payload.value });

const search = async () => {
  if (currentPage.value !== 1) {
    currentPage.value = 1;
    return;
  }
  try {
    await getDeposits({ page: currentPage.value, ...payload.value });
  } catch {
    message.error("Không tìm thấy ID người chơi", 0.5);
  }
};

watch(currentPage, (newVal) => {
  getDeposits({ page: newVal, ...payload.value });
});
</script>
