import { sessionsService } from "@/services";
import { ref } from "vue";
const sessions = ref([]);
const pagination = ref({});
let searchValue;
export default function useSessions() {
  const getSessions = async ({ incId, page, zone } = {}) => {
    searchValue =
      incId === undefined ? searchValue : incId === "" ? null : incId;
    const data = await sessionsService.fetchSessions({
      incId: searchValue,
      page,
      zone,
    });
    sessions.value = data.sessions;
    pagination.value = data.pagination;
  };
  const updateSession = async ({ id, result }) => {
    await sessionsService.updateSession({ id, result });
  };
  return {
    sessions,
    pagination,
    getSessions,
    updateSession,
  };
}
