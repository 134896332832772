import localStore from "./storage";

let token = null;
let tokenTime = null;
let renewToken = null;
const saveToken = ({ accessToken, expiresIn, refreshToken }) => {
  token = accessToken;
  tokenTime = expiresIn;
  renewToken = refreshToken;
  localStore.save("accessToken", accessToken);
  localStore.save("tokenExpiresIn", expiresIn);
  localStore.save("refreshToken", refreshToken);
};
const getToken = () => {
  if (!token) {
    token = localStore.get("accessToken");
    renewToken = localStore.get("refreshToken");
    let expiresIn = localStore.get("tokenExpiresIn");
    tokenTime = expiresIn ? expiresIn : undefined;
  }
  return {
    accessToken: token,
    expiresIn: tokenTime,
    refreshToken: renewToken,
  };
};

const clear = () => {
  localStore.clear("accessToken");
  localStore.clear("refreshToken");
  localStore.clear("tokenExpiresIn");
};

const tokenStorage = {
  saveToken,
  getToken,
  clear,
};
export default tokenStorage;
