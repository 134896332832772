<template>
  <div>
    <h1 class="p-16 text-20 font-bold bg-white mb-16">Danh sách người chơi</h1>
    <div class="p-32 pt-0">
      <AddUser />
      <UsersTable :dataSource="users" class="my-32" />
      <div class="mt-16 fixed bottom-16 right-32">
        <Pagination
          v-model:current="currentPage"
          :total="pagination.totalItems"
          :pageSize="pagination.perPage"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import Input from "@/components/Input.vue";
import Button from "@/components/Button.vue";
import UsersTable from "./components/UsersTable";
import AddUser from "./components/AddUser";
import { Pagination, message } from "ant-design-vue";
import { SearchOutlined } from "@ant-design/icons-vue";
import { ref, watch } from "vue";
import useUsers from "@/use/users";

const { users, pagination, getUsers } = useUsers();

getUsers();
const incId = ref("");
const currentPage = ref(1);

watch(currentPage, (newVal) => {
  getUsers({ page: newVal });
});
</script>
