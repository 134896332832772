<template>
  <div>
    <h1 class="p-16 text-20 font-bold bg-white mb-16">Danh sách người chơi</h1>
    <div class="p-32 pt-0">
      <div class="flex">
        <form @submit.prevent class="flex gap-8">
          <div class="flex gap-4 items-center">
            <span>ID: </span>
            <Input v-model="incId" :style="{ width: '150px' }" />
          </div>
          <Button type="primary" @click="search">
            <SearchOutlined />
            Tìm kiếm
          </Button>
        </form>
        <AddPlayer v-if="$can('edit', 'staff')" class="ml-auto" />
      </div>

      <PlayersTable :dataSource="players" class="my-32" />
      <div class="mt-16 fixed bottom-16 right-32">
        <Pagination
          v-model:current="currentPage"
          :total="pagination.totalItems"
          :pageSize="pagination.perPage"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import Input from "@/components/Input.vue";
import Button from "@/components/Button.vue";
import PlayersTable from "./components/PlayersTable";
import AddPlayer from "./components/AddPlayer";
import { Pagination, message } from "ant-design-vue";
import { SearchOutlined } from "@ant-design/icons-vue";
import { ref, watch } from "vue";
import usePlayers from "@/use/players";

const { players, pagination, getPlayers } = usePlayers();

getPlayers();
const incId = ref("");
const currentPage = ref(1);

const search = async () => {
  console.log(currentPage.value, incId.value);
  if (currentPage.value !== 1) {
    currentPage.value = 1;
    return;
  }
  try {
    await getPlayers({ page: currentPage.value, playerIncId: incId.value });
  } catch {
    message.error("Không tìm thấy ID người chơi", 0.5);
  }
};

watch(currentPage, (newVal) => {
  getPlayers({ page: newVal, playerIncId: incId.value });
});
</script>
