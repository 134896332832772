export const columns = [
  {
    title: "ID",
    key: "id",
    dataIndex: "id",
    align: "center",
  },
  {
    title: "Tên đăng nhập",
    key: "username",
    dataIndex: "username",
  },
  {
    title: "role",
    key: "role",
    dataIndex: "role",
  },
  {
    title: "",
    key: "action",
    slots: {
      customRender: "action",
    },
  },
];
