import { isEmpty } from "lodash";

const requestInterceptor = (config) => {
  const { pagination } = config;
  if (pagination && !isEmpty(pagination)) {
    const { page = 1, perPage = 20 } = pagination;
    return {
      ...config,
      params: {
        ...config.params,
        page,
        limit: perPage,
      },
    };
  }
  return config;
};

const responseInterceptor = {
  success: (response) => {
    const { data, status } = response;
    if (status === 200 && data && data.data) {
      const { page, limit, totalResults, totalPages } = data;
      delete data.page;
      delete data.limit;
      delete data.totalResults;
      delete data.totalPages;
      data.pagination = {
        page,
        perPage: limit,
        totalItems: totalResults,
        totalPages,
      };
    }
    return response;
  },
  failed: (response) => {
    return response;
  },
};

export { requestInterceptor as request, responseInterceptor as response };
