<template>
  <div>
    <h1 class="p-16 text-20 font-bold bg-white mb-16">Danh sách rút tiền</h1>
    <div class="p-32 pt-0">
      <div class="flex">
        <form @submit.prevent class="flex gap-8 flex-wrap">
          <div class="flex gap-4 items-center">
            <span>Thời gian: </span>
            <RangePicker v-model:value="date" :style="{ width: '250px' }" />
          </div>
          <Button htmlType="submit" type="primary" @click="search">
            <SearchOutlined />
            Tìm kiếm
          </Button>
        </form>
        <div class="ml-auto font-semibold text-18">
          Tổng đơn xuất: {{ pagination.totalItems }} Tổng giá trị xuất:
          {{ formatNumber(totalWithdrawalAmount) }}
        </div>
      </div>
      <WithdrawalStatsTable :dataSource="withdrawals" class="my-32" />
      <div class="mt-16 fixed bottom-16 right-32">
        <Pagination
          v-model:current="currentPage"
          :total="pagination.totalItems"
          :pageSize="pagination.perPage"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import Input from "@/components/Input.vue";
import Select from "@/components/Select.vue";
import Button from "@/components/Button.vue";
import WithdrawalStatsTable from "./components/WithdrawalStatsTable";
import { SearchOutlined } from "@ant-design/icons-vue";
import { ref, watch } from "vue";
import { Pagination, message, RangePicker } from "ant-design-vue";
import useWithdrawalsStats from "@/use/withdrawalsStats";
import { startOfDay, endOfDay } from "date-fns";
import moment from "moment";
import { formatNumber } from "@/utils";
const { getWithdrawalsStats, withdrawals, pagination, totalWithdrawalAmount } =
  useWithdrawalsStats();

const currentPage = ref(1);
const date = ref([moment(new Date()), moment(new Date())]);

getWithdrawalsStats({
  page: currentPage.value,
  from: startOfDay(parseInt(date.value[0].format("x"))),
  to: endOfDay(parseInt(date.value[1].format("x"))),
});

const search = async () => {
  if (!date.value[0]) date.value = [moment(new Date()), moment(new Date())];

  if (currentPage.value !== 1) {
    currentPage.value = 1;
    return;
  }

  getWithdrawalsStats({
    page: currentPage.value,
    from: startOfDay(parseInt(date.value[0].format("x"))),
    to: endOfDay(parseInt(date.value[1].format("x"))),
  });
};

watch(currentPage, (newVal) => {
  getWithdrawalsStats({
    page: newVal,
    from: startOfDay(parseInt(date.value[0].format("x"))),
    to: endOfDay(parseInt(date.value[1].format("x"))),
  });
});
</script>
