// import { authService } from "@/services";
import tokenStorage from "@/helpers/storageHelpers/tokenStorage";
import axios from "./axios";
import userStorage from "../storageHelpers/userStorage";
import { differenceInMinutes } from "date-fns";

let action;

const isExpired = (expiresIn) => {
  const gapMinutes = differenceInMinutes(new Date(expiresIn), new Date());
  return gapMinutes <= 4;
};

const generateNewToken = async ({ refreshToken }) => {
  try {
    const {
      data: { access, refresh },
    } = await axios
      .post("/auth/refresh-tokens", {
        refreshToken,
      })
      .then((respone) => respone.data);
    return {
      accessToken: access.token,
      refreshToken: refresh.token,
      expiresIn: access.expires,
    };
  } catch (e) {
    console.error(e);
  }
};

const renewToken = async (currentRefreshToken) => {
  const { username } = userStorage.getUser();
  try {
    const { accessToken, refreshToken, expiresIn } = await generateNewToken({
      refreshToken: currentRefreshToken,
      username,
    });
    tokenStorage.saveToken({ accessToken, refreshToken, expiresIn });
    return accessToken;
  } catch (e) {
    console.error(e);
  } finally {
    action = null;
  }
};

const getToken = async () => {
  const { accessToken, expiresIn, refreshToken } = tokenStorage.getToken();

  if (accessToken && expiresIn && isExpired(expiresIn)) {
    if (!action) {
      action = renewToken(refreshToken);
    }
    return action;
  }
  return Promise.resolve(accessToken);
};

const tokenManager = {
  getToken,
};

export default tokenManager;
