import { Ability, AbilityBuilder } from "@casl/ability";
import { ADMIN, SALE, ACCOUNTANT } from "@/common";

export default function defineRulesFor(user) {
  const { rules, can, cannot } = new AbilityBuilder(Ability);
  const { role } = user;
  if (role === ADMIN) {
    can("manage", "all");
  }
  if (role === SALE) {
    can("manage", "sessions");
    can("manage", "bets");
    can("manage", "withdrawals");
    can("manage", "deposits");
    can("manage", "players");
    can("edit", "staff");
    cannot("approve", "withdrawals");
  }
  if (role === ACCOUNTANT) {
    can("manage", "withdrawals");
    can("manage", "deposits");
    can("manage", "players");
    can("edit", "balance");
    can("approve", "withdrawals");
    can("edit", "withdrawals");
    can("edit", "playerBank");
  }

  return rules;
}
