<template>
  <div>
    <h1 class="p-16 text-20 font-bold bg-white mb-16">Lịch sử cược</h1>
    <div class="p-32 pt-0">
      <div class="flex">
        <form @submit.prevent class="flex">
          <div class="flex gap-4 items-center mr-8">
            <span>Số kỳ: </span>
            <Input v-model="incId" :style="{ width: '165px' }" />
          </div>
          <div class="flex gap-4 items-center mr-8">
            <span>ID người chơi: </span>
            <Input v-model="playerIncId" :style="{ width: '165px' }" />
          </div>
          <!-- <div class="flex gap-4 items-center mr-8">
            <span>Loại cược: </span>
            <Select
              v-model="zone"
              :options="ZONES"
              :style="{ width: '165px' }"
            />
          </div> -->
          <Button @click="search" htmlType="submit" type="primary">
            <SearchOutlined />
            Tìm kiếm
          </Button>
        </form>
      </div>
      <BetsTable :dataSource="bets" class="my-32" @mutated="getDeposits()" />
      <div class="mt-16 fixed bottom-16 right-32">
        <Pagination
          v-model:current="currentPage"
          :total="pagination.totalItems"
          :pageSize="pagination.perPage"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import Input from "@/components/Input.vue";
import Select from "@/components/Select.vue";
import Button from "@/components/Button.vue";
import BetsTable from "./components/BetsTable";
import { Pagination, message } from "ant-design-vue";
import { SearchOutlined } from "@ant-design/icons-vue";
import { ref, watch } from "vue";
import { ZONES } from "@/common";
import useBets from "@/use/bets";

const { bets, pagination, getBets } = useBets();
getBets();
const currentPage = ref(1);
const incId = ref("");
const playerIncId = ref("");
const zone = ref(null);

const search = async () => {
  if (currentPage.value !== 1) {
    currentPage.value = 1;
    return;
  }

  getBets({
    page: currentPage.value,
    incId: incId.value,
    playerIncId: playerIncId.value,
    zone: zone.value,
  });
};

watch(currentPage, (newVal) => {
  getBets({ page: newVal });
});
</script>
