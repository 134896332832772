<template>
  <div class="p-30">
    <div>
      <img src="../../assets/logo.png" class="mx-auto h-40" />
    </div>
    <div class="mx-auto mt-40 w-[500px] rounded-8 bg-white p-48 shadow-level-4">
      <div>
        <h1 class="mb-10 text-center text-22 font-semibold capitalize">
          Đăng nhập
        </h1>
        <form @submit.prevent="login">
          <div class="mb-32">
            <label class="mb-6 text-14 font-semibold">Tên tài khoản</label>
            <Input v-model="username" placeholder="Nhập tài khoản" />
          </div>
          <div class="mb-32">
            <label class="mb-6 text-14 font-semibold">Mật khẩu</label>
            <Input
              v-model="password"
              type="password"
              placeholder="Nhập mật khẩu"
            />
          </div>
          <Button type="primary" htmlType="submit" :block="true"
            >Tiếp tục</Button
          >
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import Input from "@/components/Input";
import Button from "@/components/Button.vue";
import { ref } from "vue";
import * as yup from "yup";
import { message } from "ant-design-vue";
import { LOG_IN } from "@/store/actions.type";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
const store = useStore();
const router = useRouter();

const username = ref("");
const password = ref("");
const loginSchema = yup.object().shape({
  username: yup.string().required(),
  password: yup.string().required(),
});
let isRunning = false;

const login = async () => {
  if (
    !(await loginSchema.isValid({
      username: username.value,
      password: password.value,
    }))
  ) {
    message.error("Không được để trống");
    return;
  }
  isRunning = true;
  try {
    await store.dispatch(LOG_IN, {
      username: username.value,
      password: password.value,
    });
    router.push({ name: "home" });
  } catch (e) {
    message.error("Sai tên đăng nhập hoặc mật khẩu, hãy thử lại!");
    console.error(e);
  }
  isRunning = false;
};
</script>
