<template>
  <div>
    <h1 class="p-16 text-20 font-bold bg-white mb-16">{{ ZONE[zone] }}</h1>
    <div class="p-32 pt-0">
      <form @submit.prevent class="flex gap-8">
        <div class="flex gap-4 items-center">
          <span>Số kỳ: </span>
          <Input v-model="incId" :style="{ width: '165px' }" />
        </div>
        <!-- <div class="flex gap-4 items-center">
          <span>Trạng thái: </span>
          <Select
            v-model="status"
            :options="STATUSES"
            :style="{ width: '165px' }"
          />
        </div> -->
        <Button @click="search" htmlType="submit" type="primary">
          <SearchOutlined />
          Tìm kiếm
        </Button>
      </form>
      <SessionsTable
        :dataSource="sessions"
        class="my-32"
        @mutated="
          getSessions({
            page: currentPage.value,
            incId: incId.value,
            zone,
          })
        "
      />
      <div class="mt-16 fixed bottom-16 right-32">
        <Pagination
          v-model:current="currentPage"
          :total="pagination.totalItems"
          :pageSize="pagination.perPage"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import Input from "@/components/Input.vue";
import Select from "@/components/Select.vue";
import Button from "@/components/Button.vue";
import SessionsTable from "./components/SessionsTable";
import { Pagination, message } from "ant-design-vue";
import { SearchOutlined } from "@ant-design/icons-vue";
import { ref, watch } from "vue";
import { ZONE } from "@/common";
import useSessions from "@/use/sessions";
import { useRoute, useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();
const zone = ref();
const { sessions, pagination, getSessions } = useSessions();

const validZone = () => {
  zone.value = route.params.zone;
  if (!ZONE[zone] && route.name !== "sessions") {
    router.push({ name: "players" });
  } else {
    return true;
  }
};

if (validZone()) getSessions({ zone: zone.value });

watch(route, () => {
  if (route.name === "sessions") {
    sessions.value = [];
    incId.value = "";
    if (validZone()) getSessions({ zone: zone.value, incId: incId.value });
  }
});

const currentPage = ref(1);
const incId = ref("");

const search = async () => {
  if (currentPage.value !== 1) {
    currentPage.value = 1;
    return;
  }
  try {
    await getSessions({
      page: currentPage.value,
      incId: incId.value,
      zone: zone.value,
    });
  } catch {
    message.error("Không tìm thấy số kỳ", 0.5);
  }
};

watch(currentPage, (newVal) => {
  getSessions({ page: newVal, incId: incId.value, zone: zone.value });
});
</script>
