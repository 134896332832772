<template>
  <LayoutHeader>
    <div>
      <div class="flex justify-between">
        <h1 class="text-white">Phoenix Group</h1>
        <div
          class="relative cursor-pointer"
          @mouseenter="isShow = true"
          @mouseleave="isShow = false"
        >
          <div class="relative flex text-white items-center">
            <img
              src="../assets/avatar-default.png"
              class="h-40 rounded-8 mr-4"
            />
            <span> {{ store.state.account.username }} </span>
          </div>
          <div v-show="isShow" class="absolute right-0 top-3/4 text-black">
            <span @click="logout" class="bg-white p-8 hover:bg-royal-mile"
              >Đăng xuất</span
            >
          </div>
        </div>
      </div>
    </div>
  </LayoutHeader>
</template>

<script setup>
import { LayoutHeader } from "ant-design-vue";
import { ref } from "vue";
const isShow = ref(false);
import { useStore } from "vuex";
import { LOG_OUT } from "@/store/actions.type";
const store = useStore();

const logout = () => {
  store.dispatch(LOG_OUT);
};
</script>
