<template>
  <LayoutSider
    v-model:collapsed="collapsed"
    collapsible
    :collapsedWidth="65"
    width="265"
  >
    <Menu mode="inline" theme="dark" v-model:selectedKeys="selectedKeys">
      <div class="flex h-64 items-center justify-between px-25">
        <router-link v-show="!collapsed" to="/">
          <img src="../assets/logo.png" class="h-25" alt="" />
        </router-link>
      </div>
      <MenuItem v-if="$can('manage', 'users')" key="users">
        <template #icon>
          <UserOutlined />
        </template>
        <router-link :to="{ name: 'users' }" class="font-bold">
          Danh sách người dùng
        </router-link>
      </MenuItem>
      <MenuItem v-if="$can('manage', 'players')" key="players">
        <template #icon>
          <UserOutlined />
        </template>
        <router-link :to="{ name: 'players' }" class="font-bold">
          Danh sách người chơi
        </router-link>
      </MenuItem>
      <MenuItem v-if="$can('`read`', 'deposits')" key="deposits">
        <template #icon>
          <MoneyCollectOutlined />
        </template>
        <router-link :to="{ name: 'deposits' }" class="font-bold">
          Quản lý nạp tiền
        </router-link>
      </MenuItem>
      <MenuItem v-if="$can('manage', 'withdrawals')" key="withdrawals">
        <template #icon>
          <RedEnvelopeOutlined />
        </template>
        <router-link :to="{ name: 'withdrawals' }" class="font-bold">
          Quản lý rút tiền
        </router-link>
      </MenuItem>
      <MenuItem v-if="$can('manage', 'bets')" key="bets">
        <template #icon>
          <HistoryOutlined />
        </template>
        <router-link :to="{ name: 'bets' }" class="font-bold">
          Lịch sử đặt cược
        </router-link>
      </MenuItem>
      <MenuItem v-if="$can('manage', 'sessions')" key="LEVEL_1">
        <template #icon>
          <RocketOutlined />
        </template>
        <router-link
          :to="{ name: 'sessions', params: { zone: 'LEVEL_1' } }"
          class="font-bold"
        >
          Một phút loto
        </router-link>
      </MenuItem>
      <MenuItem v-if="$can('manage', 'sessions')" key="LEVEL_3">
        <template #icon>
          <RocketOutlined />
        </template>
        <router-link
          :to="{ name: 'sessions', params: { zone: 'LEVEL_3' } }"
          class="font-bold"
        >
          Ba phút loto
        </router-link>
      </MenuItem>
      <MenuItem v-if="$can('manage', 'sessions')" key="LEVEL_5">
        <template #icon>
          <RocketOutlined />
        </template>
        <router-link
          :to="{ name: 'sessions', params: { zone: 'LEVEL_5' } }"
          class="font-bold"
        >
          Năm phút loto
        </router-link>
      </MenuItem>
      <MenuItem
        v-if="$can('manage', 'withdrawal-stats')"
        key="withdrawal-stats"
      >
        <template #icon>
          <RedEnvelopeOutlined />
        </template>
        <router-link :to="{ name: 'withdrawal-stats' }" class="font-bold">
          Thống kê lệnh rút
        </router-link>
      </MenuItem>
    </Menu>
  </LayoutSider>
</template>

<script setup>
import { LayoutSider, Menu, MenuItem } from "ant-design-vue";
import {
  UserOutlined,
  RedEnvelopeOutlined,
  MoneyCollectOutlined,
  RocketOutlined,
  HistoryOutlined,
} from "@ant-design/icons-vue";
import { ref, watch } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
const collapsed = ref(false);
const selectedKeys = ref([route.name, route.params.zone]);
watch(route, () => {
  selectedKeys.value = [route.name];
});

import useSidebar from "@/use/sidebar";
const { isCollapsed } = useSidebar();

const collapseSidebar = () => {
  collapsed.value = !collapsed.value;
  isCollapsed.value = collapsed.value;
};

watch(collapsed, (newVal) => {
  isCollapsed.value = newVal;
});
</script>
