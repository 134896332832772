import { flow } from "lodash";
import * as authInterceptors from "./authInterceptors";
import * as paginationInterceptors from "./paginationInterceptors";

const requestInterceptors = async (config) => {
  return paginationInterceptors.request(await authInterceptors.request(config));
};

const responseInterceptors = [
  flow([
    authInterceptors.response.success,
    paginationInterceptors.response.success,
  ]),
  authInterceptors.response.failed,
];

export { requestInterceptors, responseInterceptors };
