import { transactionsService } from "@/services";
import { ref } from "vue";
const withdrawals = ref([]);
const pagination = ref({});
const totalWithdrawalAmount = ref(0);
export default function useWithdrawalsStats() {
  const getWithdrawalsStats = async ({ page, from, to } = {}) => {
    const data = await transactionsService.fetchWithdrawalsStats({
      from,
      to,
      page,
    });
    withdrawals.value = data.withdrawals;
    pagination.value = data.pagination;
    totalWithdrawalAmount.value = data.totalWithdrawalAmount;
  };

  return {
    withdrawals,
    pagination,
    getWithdrawalsStats,
    totalWithdrawalAmount,
  };
}
