import { playerMapper } from "@/utils/mappers";
import { baseHttp } from "./base";

export default {
  async fetchPlayers({ playerIncId, page }) {
    const { data: players, pagination } = await baseHttp
      .get("/players", {
        params: {
          incId: playerIncId,
        },
        pagination: {
          page,
        },
      })
      .then((response) => response.data);

    return {
      players: players.map((player) => playerMapper.mapPlayer(player)),
      pagination,
    };
  },

  async createPlayer({ username, password }) {
    await baseHttp.post(`/players`, {
      username,
      password,
    });
  },

  async updatePlayer({
    id,
    username,
    password,
    bankName,
    bankAccountNumber,
    bankAccountHolder,
    active,
    isStaff,
  }) {
    await baseHttp.patch(`/players/${id}`, {
      username,
      password,
      bankName,
      bankAccountNumber,
      bankAccountHolder,
      active,
      isStaff,
    });
  },

  async recallBalance({ id, amount }) {
    await baseHttp.post(`/players/${id}/take-back-money`, {
      amount,
    });
  },
};
