import { mapDeposit } from "@/utils/mappers/depositMapper";
import { withdrawalMapper } from "@/utils/mappers";
import { baseHttp } from "./base";
import { endOfDay, startOfDay } from "date-fns";

export default {
  async fetchWithdrawals({ playerIncId, page }) {
    console.log({ playerIncId, page });
    const { data: withdrawals, pagination } = await baseHttp
      .get("/withdrawals", {
        params: {
          playerIncId,
        },
        pagination: {
          page,
        },
      })
      .then((response) => response.data);

    return {
      withdrawals: withdrawals.map((withdrawal) =>
        withdrawalMapper.mapWithdrawal(withdrawal)
      ),
      pagination,
    };
  },

  async confirmWithdrawal({ id, confirm, note }) {
    if (confirm === "approve") {
      await baseHttp.post(`/withdrawals/${id}/approve`);
    } else {
      await baseHttp.post(`/withdrawals/${id}/reject`, { note });
    }
  },

  async fetchWithdrawalsStats({ page, from, to }) {
    const {
      data: withdrawals,
      pagination,
      totalWithdrawalAmount,
    } = await baseHttp
      .get(`/withdrawals/stats`, {
        params: {
          from,
          to,
        },
        pagination: {
          page,
        },
      })
      .then((response) => response.data);

    return {
      withdrawals,
      totalWithdrawalAmount,
      pagination,
    };
  },

  async fetchDeposits({ playerIncId, page }) {
    const { data: deposits, pagination } = await baseHttp
      .get("/deposits", {
        params: {
          playerIncId,
        },
        pagination: {
          page,
        },
      })
      .then((response) => response.data);
    console.log(deposits);
    return {
      deposits: deposits.map((deposit) => mapDeposit(deposit)),
      pagination,
    };
  },

  async recallDeposit({ id }) {
    await baseHttp.post(`/deposits/${id}/revoke`);
  },

  async updateDepositNote({ id, note }) {
    await baseHttp.patch(`/deposits/${id}/note`, {
      note,
    });
  },

  async updateWithdrawal({
    id,
    bankAccountNumber,
    bankAccountHolder,
    bankName,
    note,
  }) {
    await baseHttp.patch(`/withdrawals/${id}`, {
      bankAccountNumber,
      bankAccountHolder,
      bankName,
      note,
    });
  },

  async updateNote({ id, note }) {
    await baseHttp.patch(`/withdrawals/${id}/note`, {
      note,
    });
  },

  async createDeposit({ playerIncId, amount, hasBill, isNotify, note }) {
    await baseHttp.post("/deposits", {
      playerIncId,
      amount,
      hasBill,
      isNotify,
      note,
    });
  },
};
