import { baseHttp } from "./base";
import { accountMapper } from "@/utils/mappers";

export default {
  async login({ username, password }) {
    const { data } = await baseHttp
      .post("/auth/login", {
        username,
        password,
      })
      .then((response) => response);
    return accountMapper.mapAccount(data);
  },

  async getProfile() {
    const { data } = await baseHttp
      .get("/auth/profile")
      .then((response) => response);
    return data;
  },
};
