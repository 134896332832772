import { baseHttp } from "./base";

export default {
  async fetchUsers({ page }) {
    const { data: users, pagination } = await baseHttp
      .get("/users", {
        params: {
          //   incId: playerIncId,
        },
        pagination: {
          page,
        },
      })
      .then((response) => response.data);

    return {
      users,
      pagination,
    };
  },

  async createUser({ username, password, role }) {
    await baseHttp.post("/users", {
      username,
      password,
      role,
    });
  },
};
