import { playersService } from "@/services";
import { ref } from "vue";
const players = ref([]);
const pagination = ref({});
let searchValue;

export default function usePlayers() {
  const getPlayers = async ({ page, playerIncId } = {}) => {
    searchValue =
      playerIncId === undefined
        ? searchValue
        : playerIncId === ""
        ? null
        : playerIncId;
    const data = await playersService.fetchPlayers({
      playerIncId: searchValue,
      page,
    });
    players.value = data.players;
    pagination.value = data.pagination;
  };

  const createPlayer = async ({ username, password } = {}) => {
    await playersService.createPlayer({
      username,
      password,
    });
  };

  const updatePlayer = async ({
    id,
    username,
    password,
    bankName,
    bankAccountNumber,
    bankAccountHolder,
    active,
    isStaff,
  } = {}) => {
    await playersService.updatePlayer({
      id,
      username,
      password,
      bankName,
      bankAccountNumber,
      bankAccountHolder,
      active,
      isStaff,
    });
  };
  const recallBalance = async ({ id, amount } = {}) => {
    await playersService.recallBalance({
      id,
      amount,
    });
  };
  return {
    players,
    pagination,
    getPlayers,
    updatePlayer,
    recallBalance,
    createPlayer,
  };
}
