export const PENDING = "PENDING";

export const REJECTED = "REJECTED";

export const SUCCESS = "SUCCESS";

export const APPROVED = "APPROVED";

export const REVOKED = "REVOKED";

export const LEVEL_1 = "LEVEL_1";

export const LEVEL_3 = "LEVEL_3";

export const LEVEL_5 = "LEVEL_5";

export const ONE_TO_FIVE = "ONE_TO_FIVE";

export const FINISHED = "FINISHED";

export const PREPARING = "PREPARING";

export const OPEN = "OPEN";

export const ONLINE = "ONLINE";

export const OFFLINE = "OFFLINE";

export const WEB_NAME = process.env.VUE_APP_WEB_NAME;

export const CHUC_NGAN = "CHUC_NGAN";
export const NGAN = "NGAN";
export const TRAM = "TRAM";
export const CHUC = "CHUC";
export const DON_VI = "DON_VI";
export const TREN = "TREN";
export const DUOI = "DUOI";
export const CHAN = "CHAN";
export const LE = "LE";

export const UNIT = {
  CHUC_NGAN: "Chục ngàn",
  NGAN: "Ngàn",
  TRAM: "Trăm",
  CHUC: "Chục",
  DON_VI: "Đơn vị",
  CHAN: "Đôi",
  LE: "Đơn",
  TREN: "Trên",
  DUOI: "Dưới",
  0: 0,
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
  7: 7,
  8: 8,
  9: 9,
  10: 10,
  11: 11,
  12: 12,
  13: 13,
  14: 14,
  15: 15,
  16: 16,
  17: 17,
  19: 19,
  20: 20,
  21: 21,
  22: 22,
  23: 23,
  24: 24,
  25: 25,
  26: 26,
  27: 27,
};

export const ZONE = {
  LEVEL_1: "Một phút lotto",
  LEVEL_3: "Ba phút lotto",
  LEVEL_5: "Năm phút lotto",
};

export const ZONES = [
  {
    value: LEVEL_1,
    label: ZONE[LEVEL_1],
  },
  {
    value: LEVEL_3,
    label: ZONE[LEVEL_3],
  },
  {
    value: LEVEL_5,
    label: ZONE[LEVEL_5],
  },
  {
    value: null,
    label: "Tất cả",
  },
];

export const ADMIN = "ADMIN";
export const SALE = "SALE";
export const ACCOUNTANT = "ACCOUNTANT";

export const ROLE = {
  ADMIN: "Admin",
  SALE: "Sale",
  ACCOUNTANT: "Xuất nhập khoản",
};

export const ROLES = [
  {
    value: ADMIN,
    label: ROLE[ADMIN],
  },
  {
    value: SALE,
    label: ROLE[SALE],
  },
  {
    value: ACCOUNTANT,
    label: ROLE[ACCOUNTANT],
  },
];

export const STATUS = {
  PENDING: "Đang xử lý",
  REVOKED: "Đã thu hồi",
  SUCCESS: "Thành công",
  REJECTED: "Đã hủy",
  APPROVED: "Đã duyệt",
  OPEN: "Đang mở",
  FINISHED: "Kết thúc",
  PREPARING: "Chuẩn bị",
  ONLINE: "Trực tuyến",
  OFFLINE: "Ngoại tuyến",
};

export const PAYMENT_TYPES = {
  bank: "Ngân hàng",
};

export const STATUSES = [
  {
    value: PENDING,
    label: STATUS[PENDING],
  },
  {
    value: REJECTED,
    label: STATUS[REJECTED],
  },
  {
    value: SUCCESS,
    label: STATUS[SUCCESS],
  },
  {
    value: null,
    label: "Tất cả",
  },
];
