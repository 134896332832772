import localStore from "./storage";

let localUsername = null;

const saveUser = (username) => {
  localUsername = username;
  localStore.save("username", username);
};

const getUser = () => {
  if (!localUsername) {
    localUsername = localStore.get("username");
  }
  return {
    username: localUsername,
  };
};

const clear = () => {
  localStore.clear("username");
};

const userStorage = {
  saveUser,
  getUser,
  clear,
};
export default userStorage;
