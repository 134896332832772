<template>
  <div>
    <Table
      :columns="columns"
      :dataSource="dataSource"
      :pagination="false"
      :rowKey="(record) => record.id"
      :scroll="{ x: 'max-content' }"
      :bordered="true"
    >
      <template #playerIncId="{ text: { playerIncId } }">
        <span class="font-semibold text-16">{{ playerIncId }}</span>
      </template>
      <template #amount="{ text: { amount } }">
        <span class="font-semibold text-16">{{ formatNumber(amount) }}</span>
      </template>
      <template #status="{ text: { status } }">
        <Status :variant="status === SUCCESS ? 'green' : 'red'">
          {{ STATUS[status] }}
        </Status>
      </template>
      <template #hasBill="{ text: { hasBill } }">
        <Status :variant="hasBill ? 'green' : ''">
          {{ hasBill ? "Có" : "Không" }}
        </Status>
      </template>
      <template #note="{ text: { note } }">
        <div class="max-w-[300px]">{{ note }}</div>
      </template>
      <template #action="{ text }">
        <div class="flex gap-4 justify-center">
          <!-- <Button type="primary" class="mr-8"> Duyệt </Button>
          <Button type="primary" danger class="mr-8"> Hủy </Button> -->
          <Button
            v-if="$can('edit', 'balance') && text.status !== REVOKED"
            type="primary"
            danger
            @click="showConfirm(text.id)"
          >
            Thu hồi
          </Button>
          <AddNote v-if="text.status !== REVOKED" :info="text" />
        </div>
      </template>
    </Table>
  </div>
</template>

<script setup>
import { Table, Modal, message } from "ant-design-vue";
import Button from "@/components/Button.vue";
import Status from "@/components/Status.vue";
import AddNote from "../AddNote.vue";
import { CopyOutlined, ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { columns } from "./config";
import { copyToClip, formatNumber } from "@/utils";
import { ref, createVNode } from "vue";
import { STATUS, REVOKED, SUCCESS } from "@/common";
import useDeposits from "@/use/deposits";

defineProps({
  dataSource: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(["mutated"]);
const { recallDeposit } = useDeposits();

const showConfirm = (id) => {
  Modal.confirm({
    title: () => "Bạn có muốn thu hồi lệnh này?",
    icon: () => createVNode(ExclamationCircleOutlined),
    okText: () => "Đồng ý",
    okType: "danger",
    cancelText: () => "Hủy",
    async onOk() {
      try {
        await recallDeposit({ id });
        message.success("Thành công", 0.5);
        emit("mutated");
      } catch {
        message.error("Thu hồi lỗi, vui lòng thử lại", 0.5);
      }
    },
  });
};
</script>
