<template>
  <div>
    <Table
      :columns="columns"
      :dataSource="dataSource"
      :pagination="false"
      :rowKey="(record) => record.id"
      :scroll="{ x: 'max-content' }"
      :bordered="true"
    >
      <template #incId="{ text: { incId } }">
        <span class="text-16 font-semibold">{{ incId }}</span>
      </template>
      <template #zone="{ text: { zone } }">
        <span>{{ ZONE[zone] }}</span>
      </template>
      <template #playerIncId="{ text: { playerIncId } }">
        <span class="text-16 font-semibold">{{ playerIncId }}</span>
      </template>
      <template #betPlace="{ text: { betPlace } }">
        <span>{{ getGateName(betPlace) }}</span>
      </template>
      <template #status="{ text: { status } }">
        <Status :variant="status === OPEN ? 'green' : ''">
          {{ STATUS[status] }}
        </Status>
      </template>
      <template #action="{ text }">
        <div class="flex">
          <EditBet v-show="text.status !== FINISHED" :info="text">
            Sửa cược
          </EditBet>
        </div>
      </template>
    </Table>
  </div>
</template>

<script setup>
import { Table, Modal, message } from "ant-design-vue";
import Button from "@/components/Button.vue";
import Status from "@/components/Status.vue";
import { CopyOutlined, ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { columns } from "./config";
import { copyToClip, formatNumber } from "@/utils";
import { ref, createVNode } from "vue";
import { STATUS, FINISHED, ZONE, OPEN } from "@/common";
import EditBet from "../EditBet.vue";
import { UNIT } from "@/common";
// import useDeposits from "@/use/deposits";

defineProps({
  dataSource: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(["mutated"]);
const getGateName = (value) => {
  let arrayName = value.split("_");
  let num = arrayName[arrayName.length - 1];
  arrayName.pop();
  return UNIT[arrayName.join("_")] + "_" + UNIT[num];
};
</script>
