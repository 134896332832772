import { betsService } from "@/services";
import { reactive, ref } from "vue";
import { forEach, uniq } from "lodash";
import { UNIT } from "@/common";
const bets = ref([]);
const pagination = ref({});
const configs = ref({ tongHoaGates: [], oneToFiveGates: [] });
let searchValues = {};
let units = [];
let numbers = [];
let unit;
let numb;
let currentVal;
export default function useBets() {
  const getBets = async ({ incId, page, playerIncId, zone } = {}) => {
    searchValues = {
      page: page === "" ? null : page,
      incId: incId === "" ? null : incId,
      playerIncId: playerIncId === "" ? null : playerIncId,
      zone: zone === "" ? null : zone,
    };
    const data = await betsService.fetchBets({
      ...searchValues,
    });

    bets.value = data.bets;
    pagination.value = data.pagination;
  };

  const upadtePlayerBetPlace = async ({ id, betPlace }) => {
    await betsService.upadtePlayerBetPlace({ id, betPlace });
  };

  const getBetConfigs = async () => {
    const data = await betsService.fetchConfigs();
    forEach(data.configs.oneToFiveGates, (value, key) => {
      currentVal = value.split("_");
      numb = currentVal.pop();
      unit = currentVal.join("_");

      units.push(unit);
      numbers.push(numb);
    });

    units = uniq(units).map((unit) => {
      return { value: unit, label: UNIT[unit] };
    });
    numbers = uniq(numbers).map((numb) => {
      return { value: numb, label: UNIT[numb] };
    });
    configs.value.oneToFiveGates = { units, numbers };
    forEach(data.configs.tongHoaGates, (value, key) => {
      configs.value.tongHoaGates.push({ value: key, label: UNIT[value] });
    });
  };
  return {
    bets,
    pagination,
    configs,
    getBets,
    upadtePlayerBetPlace,
    getBetConfigs,
  };
}
