<template>
  <div class="flex gap-8">
    <Button type="primary" @click="visible = true">
      <PlusOutlined />
      Thêm nghiệp vụ viên
    </Button>
    <Modal
      v-model:visible="visible"
      title="Thêm tài khoản nghiệp vụ viên"
      @ok="addPlayer"
    >
      <form @submit.prevent="addUser">
        <div class="mb-16">
          Tên đăng nhập:
          <Input v-model="username" />
        </div>
        <div class="mb-16">
          Mật khẩu:
          <Input v-model="password" type="password" />
        </div>
      </form>
    </Modal>
  </div>
</template>

<script setup>
import { message, Modal, Switch } from "ant-design-vue";
import { PlusOutlined } from "@ant-design/icons-vue";
import Input from "@/components/Input.vue";
import Button from "@/components/Button.vue";
import Select from "@/components/Select.vue";
import { ref, watch } from "vue";
import usePlayers from "@/use/players";
const username = ref();
const password = ref();
const visible = ref(false);

const { createPlayer, getPlayers } = usePlayers();
let isRunning = false;
const addPlayer = async () => {
  if (isRunning) return;
  isRunning = true;
  try {
    await createPlayer({
      username: username.value,
      password: password.value,
    });
    message.success("Tạo nghiệp vụ viên thành công");
    visible.value = false;
    getPlayers();
  } catch {
    message.error("Lỗi vui lòng thử lại");
  }
  isRunning = false;
};

watch(visible, () => {
  if (visible.value) {
    username.value = "";
    password.value = "";
  }
});
</script>
