import { format } from "date-fns";
import { formatNumber } from "../formatNumber";
export const mapBet = ({
  id,
  amount,
  game,
  gate,
  player,
  session,
  winnings,
  createdAt,
}) => {
  return {
    id,
    amountBet: formatNumber(amount),
    amountWinnings: formatNumber(winnings),
    game,
    betPlace: gate,
    playerIncId: player.incId,
    incId: session.incId,
    zone: session.zone,
    status: session.status,
    time: format(new Date(createdAt), "hh:mm dd/MM/yyyy"),
  };
};
