import tokenManager from "../tokenManager";
import store from "@/store";
import { LOG_OUT } from "@/store/actions.type";

const whitelist = ["/login"];

const isWhitelist = (path) => {
  return whitelist.findIndex((base) => path.indexOf(base) > -1) > -1;
};

const requestInterceptor = (config) => {
  return tokenManager
    .getToken()
    .then((accessToken) => {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${accessToken}`,
      };
      return config;
    })
    .catch(() => config);
};

const responseInterceptor = {
  success: (response) => {
    const { results } = response.data;
    if (results) {
      response.data = { data: results, ...response.data };
    }
    return response;
  },
  failed: (error) => {
    const { status, config } = error.response;
    if (status === 401 && !isWhitelist(config.url)) {
      store.dispatch(LOG_OUT);
    }
    return Promise.reject(error);
  },
};

export { requestInterceptor as request, responseInterceptor as response };
