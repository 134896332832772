import axios from "axios";
import {
  requestInterceptors,
  responseInterceptors,
} from "@/helpers/httpHelpers/interceptors";

const BASE_API_URL = process.env.VUE_APP_BASE_API_URL;

const baseHttp = axios.create({
  baseURL: BASE_API_URL,
});
baseHttp.interceptors.request.use(requestInterceptors);
baseHttp.interceptors.response.use(...responseInterceptors);
export { baseHttp };
