<template>
  <div>
    <Select
      v-model:value="value"
      :mode="mode"
      :placeholder="placeholder"
      :options="options"
      :style="style"
      @select="onSelect"
    ></Select>
  </div>
</template>

<script setup>
import { Select } from "ant-design-vue";
import { ref, watch } from "vue";

const props = defineProps({
  options: {
    type: Array,
    default: () => [],
  },
  modelValue: {
    type: String,
  },
  mode: {
    type: String,
  },
  style: {
    type: Object,
  },
  placeholder: {
    type: String,
  },
});
const emit = defineEmits(["update:modelValue"]);
const value = ref([props.modelValue]);
const onSelect = () => {
  emit("update:modelValue", value.value);
};
watch(props, () => {
  value.value = props.modelValue;
});
</script>
