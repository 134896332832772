<template>
  <div>
    <Button type="primary" @click="visible = true">Sửa cược</Button>
    <Modal v-model:visible="visible" title="Sửa cược" @ok="editBetPlace">
      <div>
        <div class="mb-16">
          Đơn vị cược:
          <Select
            v-show="info.game === ONE_TO_FIVE"
            v-model="unit"
            :options="units"
            placeholder="Lựa chọn đơn vị cược"
            :disabled="true"
            :style="{ width: '100%' }"
          />
        </div>
        <div class="mb-16">
          Số cược:
          <Select
            v-model="number"
            :options="numbers"
            placeholder="Lựa chọn số cược"
            :style="{ width: '100%' }"
          />
        </div>
      </div>
    </Modal>
  </div>
</template>

<script setup>
import { Modal, message } from "ant-design-vue";
import Input from "@/components/Input.vue";
import Button from "@/components/Button.vue";
import Select from "@/components/Select.vue";
import { ref, watch } from "vue";
import { ONE_TO_FIVE } from "@/common";
import useBets from "@/use/bets";

const { configs, upadtePlayerBetPlace, getBets } = useBets();

const props = defineProps({
  info: {
    type: Object,
    required: true,
  },
});

const visible = ref(props.modelValue);
let val = props.info.betPlace.split("_");

const number = ref(val.pop());
const unit = ref(val.join("_"));
const units = ref();
const numbers = ref();

const editBetPlace = async () => {
  try {
    await upadtePlayerBetPlace({
      id: props.info.id,
      betPlace: unit.value + "_" + number.value,
    });
    message.success("Sửa cược thành công");
    visible.value = false;
    getBets();
  } catch {
    message.error("Lỗi, vui lòng thử lại");
  }
};
if (props.info.game !== ONE_TO_FIVE) {
  numbers.value = configs.value.tongHoaGates;
} else {
  units.value = configs.value.oneToFiveGates.units;
  numbers.value = configs.value.oneToFiveGates.numbers;
}
</script>
