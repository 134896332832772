export const columns = [
  {
    title: "Số kỳ",
    key: "incId",
    slots: { customRender: "incId" },
    align: "center",
  },
  {
    title: "Kết quả xổ số",
    key: "result",
    slots: { customRender: "result" },
    align: "center",
  },
  {
    title: "Tổng tiền cược",
    key: "totalBet",
    align: "center",
    slots: { customRender: "totalBet" },
  },
  {
    title: "Trạng thái",
    key: "stauts",
    align: "center",
    slots: { customRender: "status" },
  },
  {
    title: "Thời gian bắt đầu",
    key: "startTime",
    dataIndex: "startTime",
  },
  {
    title: "Thời gian kết thúc",
    key: "endTime",
    dataIndex: "endTime",
  },
  {
    title: "",
    key: "action",
    slots: {
      customRender: "action",
    },
  },
];
