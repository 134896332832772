<template>
  <Table
    :columns="columns"
    :dataSource="dataSource"
    :pagination="false"
    :rowKey="(record) => record.id"
    :bordered="true"
    :scroll="{ x: 'max-content' }"
  >
    <template #playerIncId="{ text: { playerIncId } }">
      <span class="font-semibold text-16">{{ playerIncId }}</span>
    </template>
    <template #amount="{ text: { amount } }">
      <button
        @click="copyToClip(amount * 1000)"
        class="flex items-center gap-4 font-semibold text-16"
      >
        <span>{{ formatNumber(amount) }}</span>
        <CopyOutlined />
      </button>
    </template>
    <template #status="{ text: { status } }">
      <Status
        :variant="
          status === PENDING ? 'yellow' : status === APPROVED ? 'green' : 'red'
        "
      >
        {{ STATUS[status] }}
      </Status>
    </template>
    <template #type="{ text }">
      {{ PAYMENT_TYPES[text.type] }}
    </template>
    <template #bankAccountNumber="{ text: { bankAccountNumber } }">
      <button
        @click="copyToClip(bankAccountNumber)"
        class="flex items-center gap-4"
      >
        <span>{{ bankAccountNumber }}</span>
        <CopyOutlined />
      </button>
    </template>
    <template #note="{ text: { note } }">
      <div class="max-w-[300px]">{{ note }}</div>
    </template>
    <template #action="{ text }">
      <div class="flex gap-4 justify-center">
        <div v-show="text.status === PENDING">
          <Button
            v-if="$can('approve', 'withdrawals')"
            v-show="text.status === PENDING"
            type="primary"
            class="mr-8"
            @click="confirm(text.id, 'approve')"
          >
            Duyệt
          </Button>
        </div>
        <EditWithdrawal :info="text" />
      </div>
    </template>
  </Table>
</template>

<script setup>
import { Table, message } from "ant-design-vue";
import Button from "@/components/Button.vue";
import Status from "@/components/Status.vue";
import EditWithdrawal from "../EditWithdrawal.vue";
import { CopyOutlined } from "@ant-design/icons-vue";
import { columns } from "./config";
import { copyToClip, formatNumber } from "@/utils";
import { STATUS, PAYMENT_TYPES, PENDING, APPROVED } from "@/common";
import useWithdrawals from "@/use/withdrawals";

defineProps({
  dataSource: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(["mutated"]);

const { confirmWithdrawal } = useWithdrawals();

const confirm = async (id, type) => {
  try {
    await confirmWithdrawal({ id, confirm: type });
    message.success("Thành công");
    emit("mutated");
  } catch (e) {
    console.log(e);
    message.error("Lỗi, vui lòng thử lại");
  }
};
</script>
