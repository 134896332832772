import { baseHttp } from "./base";
import { betMapper } from "@/utils/mappers";
export default {
  async fetchBets({ incId, page, playerIncId, zone }) {
    const { data: bets, pagination } = await baseHttp
      .get("/bets", {
        params: {
          sessionIncId: incId,
          playerIncId,
          zone,
        },
        pagination: {
          page,
        },
      })
      .then((response) => response.data);

    return {
      bets: bets.map((bet) => betMapper.mapBet(bet)),
      pagination,
    };
  },

  async upadtePlayerBetPlace({ id, betPlace }) {
    await baseHttp.patch(`/bets/${id}`, { gate: betPlace });
  },

  async fetchConfigs() {
    const { data: configs } = await baseHttp
      .get("/configs")
      .then((response) => response);
    return {
      configs,
    };
  },
};
