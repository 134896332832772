export const columns = [
  {
    title: "ID",
    key: "incId",
    dataIndex: "incId",
    align: "center",
  },
  {
    title: "Tên đăng nhập",
    key: "username",
    dataIndex: "username",
  },
  {
    title: "Trạng thái",
    key: "status",
    slots: {
      customRender: "status",
    },
  },
  {
    title: "Tình trạng",
    key: "active",
    slots: {
      customRender: "active",
    },
  },
  {
    title: "Số dư",
    key: "balance",
    dataIndex: "balance",
    align: "center",
  },
  {
    title: "Tổng nạp",
    key: "totalDeposit",
    dataIndex: "totalDeposit",
    align: "center",
  },
  {
    title: "Tổng thưởng",
    key: "totalBonus",
    dataIndex: "totalBonus",
    align: "center",
  },
  {
    title: "Tổng thắng",
    key: "totalWinnings",
    dataIndex: "totalWinnings",
    align: "center",
  },
  {
    title: "Tổng thua",
    key: "totalLoss",
    dataIndex: "totalLoss",
    align: "center",
  },
  {
    title: "Tổng cược",
    key: "totalBet",
    dataIndex: "totalBet",
    align: "center",
  },
  {
    title: "Tổng rút",
    key: "totalWithdrawal",
    dataIndex: "totalWithdrawal",
    align: "center",
  },
  {
    title: "Ngân hàng",
    key: "bankName",
    dataIndex: "bankName",
  },
  {
    title: "Số tài khoản",
    key: "bankAccountNumber",
    dataIndex: "bankAccountNumber",
  },
  {
    title: "Chủ tài khoản",
    key: "bankAccountHolder",
    dataIndex: "bankAccountHolder",
  },
  // {
  //   title: "Trạng thái",
  //   key: "active",
  //   slots: {
  //     customRender: "active",
  //   },
  // },
  {
    title: "",
    key: "action",
    slots: {
      customRender: "action",
    },
  },
];
