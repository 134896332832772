import { usersService } from "@/services";
import { ref } from "vue";
const users = ref([]);
const pagination = ref({});

export default function useUsers() {
  const getUsers = async ({ page } = {}) => {
    const data = await usersService.fetchUsers({
      page,
    });
    users.value = data.users;
    pagination.value = data.pagination;
  };
  const createUser = async ({ username, password, role }) => {
    await usersService.createUser({ username, password, role });
  };
  return {
    users,
    pagination,
    getUsers,
    createUser,
  };
}
