import { Ability } from "@casl/ability";
import defineRulesFor from "./rules";

const ability = new Ability();

export function setAbilitiesFor(user) {
  ability.update(defineRulesFor(user));
}

export default ability;
