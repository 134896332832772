export const columns = [
  {
    title: "ID người chơi",
    key: "playerIncId",
    dataIndex: "playerIncId",
    align: "center",
  },
  {
    title: "Giá trị",
    key: "amount",
    slots: { customRender: "amount" },
  },
  {
    title: "Ngân hàng",
    key: "bankName",
    dataIndex: "bankName",
  },
  {
    title: "Số tài khoản",
    key: "bankAccountNumber",
    dataIndex: "bankAccountNumber",
  },
  {
    title: "Chủ tài khoản",
    key: "bankAccountHolder",
    dataIndex: "bankAccountHolder",
  },
  {
    title: "Thời gian",
    key: "time",
    dataIndex: "time",
  },
];
