import { format } from "date-fns";

export const mapSession = ({
  id,
  incId,
  result,
  totalBet,
  startTime,
  endTime,
  status,
}) => {
  return {
    id,
    incId,
    result,
    totalBet,
    startTime: format(new Date(startTime), "hh:mm dd/MM/yyyy"),
    endTime: format(new Date(endTime), "hh:mm dd/MM/yyyy"),
    status,
  };
};
