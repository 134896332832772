<template>
  <div>
    <Button @click="visible = true">Sửa</Button>
    <Modal
      v-model:visible="visible"
      title="Sửa tài khoản người chơi"
      @ok="editPlayer"
    >
      <form @submit.prevent="editPlayer">
        <div class="mb-16">
          Tên đăng nhập:
          <Input v-model="username" :disabled="true" />
        </div>
        <div class="mb-16">
          Mật khẩu:
          <Input v-model="password" />
        </div>
        <div class="flex gap-16 mb-16">
          <div>
            Hoạt động:
            <Switch v-model:checked="active" />
          </div>
          <div v-if="$can('manage', 'staff')">
            Là nghiệp vụ viên:
            <Switch v-model:checked="isStaff" />
          </div>
        </div>
      </form>
    </Modal>
  </div>
</template>

<script setup>
import { Modal, Switch, message } from "ant-design-vue";
import Input from "@/components/Input.vue";
import Button from "@/components/Button.vue";
import Select from "@/components/Select.vue";
import { ref, watch } from "vue";
import usePlayers from "@/use/players";

const props = defineProps({
  info: {
    type: Object,
    required: true,
  },
});
const { updatePlayer, getPlayers } = usePlayers();

const username = ref(props.info.username);
const password = ref(props.info.password);
const active = ref(props.info.active);
const isStaff = ref(props.info.isStaff);
const visible = ref(props.modelValue);

const editPlayer = async () => {
  try {
    await updatePlayer({
      id: props.info.id,
      username: username.value,
      password: password.value,
      isStaff: isStaff.value,
      active: active.value,
    });
    visible.value = false;
    message.success("Cập nhật thành công");
    getPlayers();
  } catch (e) {
    console.error(e);
    message.error("Lỗi, vui lòng thử lại");
  }
};

watch(visible, () => {
  if (visible.value) {
    username.value = props.info.username;
    password.value = props.info.password;
    active.value = props.info.active;
    isStaff.value = props.info.isStaff;
  }
});
</script>
