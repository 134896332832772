const save = (key, data) => {
  localStorage.setItem(key, data);
};
const get = (key) => {
  return localStorage.getItem(key);
};
const clear = (key) => {
  if (key) {
    localStorage.removeItem(key);
  } else {
    localStorage.clear();
  }
};
const localStore = {
  save,
  get,
  clear,
};

export default localStore;
