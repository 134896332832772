<template>
  <div>
    <Button @click="visible = true">Sửa</Button>
    <Modal
      v-model:visible="visible"
      title="Sửa tài khoản người chơi"
      @ok="editUser"
    >
      <form @submit.prevent="editUser">
        <div class="mb-16">
          Tên đăng nhập:
          <Input v-model="username" :disabled="true" />
        </div>
        <div class="mb-16">
          Mật khẩu:
          <Input v-model="password" />
        </div>
        <!-- <div>
          Hoạt động:
          <Switch v-model:checked="active" />
        </div> -->
      </form>
    </Modal>
  </div>
</template>

<script setup>
import { Modal, Switch } from "ant-design-vue";
import Input from "@/components/Input.vue";
import Button from "@/components/Button.vue";
import Select from "@/components/Select.vue";
import { ref, watch } from "vue";
const props = defineProps({
  info: {
    type: Object,
    required: true,
  },
});

const username = ref(props.info.username);
const password = ref(props.info.password);
// const active = ref(props.info.active);
const visible = ref(props.modelValue);

const editUser = () => {
  console.log("something");
};

watch(visible, () => {
  if (visible.value) {
    username.value = props.info.username;
    password.value = props.info.password;
    // active.value = props.info.active;
  }
});
</script>
