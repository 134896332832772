import { formatNumber } from "../formatNumber";

export const mapPlayer = ({
  balance,
  totalDeposit,
  totalBet,
  totalBonus,
  totalWinnings,
  totalLoss,
  totalWithdrawal,
  ...player
}) => {
  return {
    balance: formatNumber(balance),
    totalDeposit: formatNumber(totalDeposit),
    totalBet: formatNumber(totalBet),
    totalBonus: formatNumber(totalBonus),
    totalWinnings: formatNumber(totalWinnings),
    totalLoss: formatNumber(totalLoss),
    totalWithdrawal: formatNumber(totalWithdrawal),
    ...player,
  };
};
