export const mapAccount = ({ tokens, user }) => {
  let { access, refresh } = tokens;
  const { id, role, username, isVerified } = user;
  return {
    accessToken: access.token,
    expiresIn: access.expires,
    id,
    username,
    role,
    isVerified,
    refreshToken: refresh.token,
  };
};
