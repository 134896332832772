import { LOG_IN, LOG_OUT, GET_ACCOUNT_INFO_LOCAL } from "../actions.type";
import { SET_ACCOUNT_INFO, REMOVE_ACCOUNT } from "../mutations.type";
import tokenStorage from "@/helpers/storageHelpers/tokenStorage";
import userStorage from "@/helpers/storageHelpers/userStorage";
import { authService } from "@/services";
import tokenManager from "@/helpers/httpHelpers/tokenManager";

export default {
  state: () => ({
    id: null,
    username: "",
    name: "",
    role: "",
  }),
  getters: {
    isAuthenticated(state) {
      return !!state.id;
    },
    role(state) {
      return state.role;
    },
  },
  mutations: {
    [SET_ACCOUNT_INFO](state, { id, username, role, name }) {
      state.id = id;
      state.username = username;
      state.role = role;
      state.name = name;
    },
    [REMOVE_ACCOUNT](state) {
      state.id = null;
      state.username = "";
      state.role = "";
      state.name = "";
    },
  },
  actions: {
    async [LOG_IN]({ commit }, values) {
      const { id, username, role, name, accessToken, expiresIn, refreshToken } =
        await authService.login({
          username: values.username,
          password: values.password,
        });
      tokenStorage.saveToken({ accessToken, expiresIn, refreshToken });
      userStorage.saveUser(username);
      commit(SET_ACCOUNT_INFO, { name, role, id, username });
    },
    [LOG_OUT]({ commit }) {
      tokenStorage.clear();
      userStorage.clear();
      commit(REMOVE_ACCOUNT);
      window.location.replace(`${window.location.origin}/login`);
    },
    async [GET_ACCOUNT_INFO_LOCAL]({ commit }) {
      try {
        const result = await authService.getProfile();
        commit(SET_ACCOUNT_INFO, result);
      } catch (e) {
        console.error(e);
        commit(REMOVE_ACCOUNT);
      }
    },
  },
};
