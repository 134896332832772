import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Withdrawal from "@/views/Withdrawal/Withdrawal";
import Deposit from "@/views/Deposit/Deposit";
import Players from "@/views/Players/Players";
import Login from "@/views/Auth/Login";
import routerHelper from "./routerHelpper";
import Sessions from "@/views/Session/Sessions";
import Bets from "@/views/Bets/Bets";
import Users from "@/views/Users/Users";
import WithdrawalStats from "@/views/WithdrawalStats/WithdrawalStats";

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/",
    name: "home",
    component: Home,
    redirect: {
      name: "players",
    },
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "players",
        name: "players",
        component: Players,
        meta: { resource: "players" },
      },
      {
        path: "withdrawals",
        name: "withdrawals",
        component: Withdrawal,
        meta: { resource: "withdrawals" },
      },
      {
        path: "deposits",
        name: "deposits",
        component: Deposit,
        meta: { resource: "deposits" },
      },
      {
        path: "bets",
        name: "bets",
        component: Bets,
        meta: { resource: "bets" },
      },
      {
        path: "sessions/:zone",
        name: "sessions",
        component: Sessions,
        meta: { resource: "sessions" },
      },
      {
        path: "users",
        name: "users",
        component: Users,
        meta: { resource: "users" },
      },
      {
        path: "withdrawal-stats",
        name: "withdrawal-stats",
        component: WithdrawalStats,
        meta: { resource: "withdrawal-stats" },
      },
    ],
  },
  { path: "/:pathMatch(.*)*", redirect: { name: "players" } },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach(routerHelper.beforeEach);

export default router;
