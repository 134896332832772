import { transactionsService } from "@/services";
import { ref } from "vue";
const deposits = ref([]);
const pagination = ref({});
let searchValue = "";
export default function useDeposits() {
  const getDeposits = async ({ page, playerIncId } = {}) => {
    searchValue =
      playerIncId === undefined
        ? searchValue
        : playerIncId === ""
        ? null
        : playerIncId;
    const data = await transactionsService.fetchDeposits({
      playerIncId: searchValue,
      page,
    });
    deposits.value = data.deposits;
    pagination.value = data.pagination;
  };
  const recallDeposit = async ({ id }) => {
    await transactionsService.recallDeposit({ id });
  };

  const createDeposit = async ({
    playerIncId,
    amount,
    hasBill,
    isNotify,
    note,
  } = {}) => {
    await transactionsService.createDeposit({
      playerIncId,
      amount,
      hasBill,
      isNotify,
      note,
    });
  };

  const updateDepositNote = async ({ id, note }) => {
    await transactionsService.updateDepositNote({ id, note });
  };

  return {
    deposits,
    pagination,
    getDeposits,
    recallDeposit,
    createDeposit,
    updateDepositNote,
  };
}
