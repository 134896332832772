<template>
  <div class="flex gap-8 ml-auto">
    <Button type="primary" @click="showAddDepositModal">
      <PlusOutlined />
      Nạp tiền
    </Button>
    <!-- <Button type="primary" @click="showAddDepositModal('noPoint')">
      <PlusOutlined />
      Nạp tiền (không cộng điểm)
    </Button> -->
    <Button @click="showAddDepositModal('promotion')">
      <PlusOutlined />
      Nạp Khuyến mãi
    </Button>

    <Modal
      v-model:visible="visible"
      title="Thêm lệnh nạp tiền"
      @ok="addDeposit"
    >
      <form @submit.prevent="addDeposit">
        <div class="mb-32">
          ID người chơi:
          <Input v-model="playerIncId" type="number" />
        </div>
        <div class="mb-32">
          Giá trị:
          <Input v-model="amount" type="number" />
        </div>
        <div v-show="!hasBill" class="mb-32">
          <div>
            Hiện thông báo:
            <Switch v-model:checked="isNotify" />
          </div>
        </div>
        <!-- <div v-show="hasBill" class="mb-32">
          <div>
            Là thuế web, phí kéo:
            <Switch v-model:checked="isTowingFee" />
          </div>
        </div> -->
      </form>
    </Modal>
  </div>
</template>

<script setup>
import Modal from "@/components/Modal.vue";
import Button from "@/components/Button.vue";
import Input from "@/components/Input.vue";
import { Switch, message } from "ant-design-vue";
import Select from "@/components/Select.vue";
import { PlusOutlined } from "@ant-design/icons-vue";
import { ref, watch } from "vue";
import useDeposits from "@/use/deposits";
import { WEB_NAME } from "@/common";
import { formatNumber } from "@/utils";

const emit = defineEmits(["mutated"]);
const { createDeposit } = useDeposits();
const visible = ref(false);
const showAddDepositModal = (type) => {
  if (type === "promotion") {
    hasBill.value = false;
    isNotify.value = true;
  } else {
    hasBill.value = true;
    isNotify.value = false;
  }
  visible.value = true;
};

const playerIncId = ref("");
const amount = ref("");
const hasBill = ref(false);
const isNotify = ref(true);
let isRunning = false;
const getNotifyContent = () =>
  `Chúc mừng quý khách đã nhận phần quà may mắn từ ${WEB_NAME}, trị giá ${formatNumber(
    amount.value * 1000
  )} VNĐ`;

const addDeposit = async () => {
  if (isRunning) return;
  isRunning = true;
  try {
    await createDeposit({
      playerIncId: playerIncId.value,
      amount: amount.value,
      hasBill: hasBill.value,
      isNotify: isNotify.value,
      note: isNotify.value ? getNotifyContent() : undefined,
    });
    message.success("Nạp tiền thành công");
    emit("mutated");
    visible.value = false;
  } catch {
    message.error("Lỗi, vui lòng thử lại");
  }
  isRunning = false;
};

watch(visible, (newVal) => {
  if (newVal) {
    amount.value = "";
    playerIncId.value = "";
  }
});
</script>
