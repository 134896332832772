<template>
  <div>
    <Button @click="visible = true">Sửa ghi chú</Button>
    <Modal v-model:visible="visible" title="Sửa ghi chú" @ok="addNote">
      <form @submit.prevent="addNote">
        <Textarea
          v-model:value="noteContent"
          placeholder="Thêm ghi chú"
          auto-size
        />
      </form>
    </Modal>
  </div>
</template>

<script setup>
import { Textarea, Modal, message } from "ant-design-vue";
import Input from "@/components/Input.vue";
import Button from "@/components/Button.vue";
import { ref, watch } from "vue";
import useDeposits from "@/use/deposits";

const { updateDepositNote, getDeposits } = useDeposits();

const props = defineProps({
  info: {
    type: Object,
    required: true,
  },
});
const noteContent = ref(props.info.note);
const visible = ref(false);

const addNote = async () => {
  try {
    await updateDepositNote({
      id: props.info.id,
      note: noteContent.value || undefined,
    });
    message.success("Cập nhật thành công");
    getDeposits();
    visible.value = false;
  } catch (e) {
    console.error(e);
    message.error("Lỗi vui lòng thử lại");
  }
};

watch(visible, () => {
  if (visible.value) {
    noteContent.value = props.info.note;
  }
});
</script>
