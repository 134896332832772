<template>
  <div>
    <Button @click="visible = true" type="primary">Sửa kết quả</Button>
    <Modal v-model:visible="visible" title="Sửa kết quả" @ok="editResult">
      <form @submit.prevent="editResult">
        <div class="font-bold">
          <span class="inline-block w-[100px]">Số kỳ</span>
          <span>{{ info.incId }}</span>
        </div>
        <div class="flex items-center">
          <span class="inline-block w-[100px] font-bold"> Kết quả sửa: </span>
          <div class="flex-1">
            <div class="form-group-input flex items-center gap-4 mb-3">
              <input
                v-for="(num, index) in result"
                :key="index"
                :value="num"
                type="text"
                maxlength="1"
                class="w-25 h-25 border border-royal-mile rounded-4 text-center"
                @keydown="validInput($event, index)"
              />
            </div>
          </div>
        </div>
      </form>
    </Modal>
  </div>
</template>

<script setup>
import { Modal, Switch, message } from "ant-design-vue";
import Input from "@/components/Input.vue";
import Button from "@/components/Button.vue";
import { ref, watch } from "vue";
import useSessions from "@/use/sessions";
const props = defineProps({
  info: {
    type: Object,
    required: true,
  },
});
const emit = defineEmits(["mutated"]);
const { updateSession } = useSessions();
const result = ref(props.info.result.split(""));
const visible = ref(false);

const editResult = async () => {
  if (result.value.join("").length < 5) {
    message.error("Vui lòng điền đủ kết quả", 0.5);
    return;
  }
  try {
    await updateSession({ id: props.info.id, result: result.value.join("") });
    message.success("Cập nhật thành công");
    visible.value = false;
    emit("mutated");
  } catch {
    message.error("Lỗi, vui lòng thử lại");
  }
};

watch(visible, () => {
  if (visible.value) {
    result.value = props.info.result.split("");
  }
});
const isDigit = /^\d+$/;

const validInput = (e, index) => {
  if (e.keyCode === 8 || e.keyCode === 46) {
    e.preventDefault();
    e.target.value = "";
    result.value[index] = "";
    if (index === 0) return;
    e.target.previousElementSibling.focus();
    return;
  }

  if (!isDigit.test(e.key)) {
    e.preventDefault();
    return;
  }

  // if (e.target.value) {
  //   e.preventDefault();
  //   result.value[index + 1] = e.key;
  // } else {
  result.value[index] = e.key;
  if (index === 4) return;
  e.target.nextElementSibling.focus();
  // }
};
</script>
