import { transactionsService } from "@/services";
import { ref } from "vue";
const withdrawals = ref([]);
const pagination = ref({});
let searchValue = "";
export default function useWithdrawals() {
  const getWithdrawals = async ({ page, playerIncId } = {}) => {
    searchValue =
      playerIncId === undefined
        ? searchValue
        : playerIncId === ""
        ? null
        : playerIncId;
    const data = await transactionsService.fetchWithdrawals({
      playerIncId: searchValue,
      page,
    });
    withdrawals.value = data.withdrawals;
    pagination.value = data.pagination;
  };
  const confirmWithdrawal = async ({ id, confirm, note }) => {
    await transactionsService.confirmWithdrawal({ id, confirm, note });
  };

  const updateWithdrawal = async ({
    id,
    note,
    bankName,
    bankAccountHolder,
    bankAccountNumber,
  }) => {
    await transactionsService.updateWithdrawal({
      id,
      note,
      bankName,
      bankAccountHolder,
      bankAccountNumber,
    });
  };
  const updateNote = async ({ id, note }) => {
    await transactionsService.updateNote({
      id,
      note,
    });
  };
  return {
    withdrawals,
    pagination,
    getWithdrawals,
    confirmWithdrawal,
    updateWithdrawal,
    updateNote,
  };
}
