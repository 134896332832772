import store from "@/store";
import { GET_ACCOUNT_INFO_LOCAL, LOG_OUT } from "@/store/actions.type";
import ability from "@/helpers/abilityHelper";

const isAuthenticated = () => {
  return store.getters.isAuthenticated;
};

const checkPermission = (to) => {
  return !to.matched.some((route) => {
    if (!route.meta.resource) {
      return false;
    }
    return ability.cannot(route.meta.action || "read", route.meta.resource);
  });
};

const routerHelper = {
  beforeEach(to, from, next) {
    // check auth
    if (to.meta.requiresAuth && !isAuthenticated()) {
      if (!from.name) {
        (async () => {
          await store.dispatch(GET_ACCOUNT_INFO_LOCAL);
          console.log(!checkPermission(to));
          if (isAuthenticated()) {
            if (!checkPermission(to)) {
              return next("/");
            } else {
              next();
            }
          } else {
            store.dispatch(LOG_OUT);
          }
        })();
      } else {
        next("/login");
      }
    } else {
      next();
    }
  },
};

export default routerHelper;
