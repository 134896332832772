import { baseHttp } from "./base";
import { LEVEL_1, LEVEL_3, LEVEL_5 } from "@/common";
import { sessionMapper } from "@/utils/mappers";
export default {
  async fetchSessions({ incId, page, zone }) {
    const { data: sessions, pagination } = await baseHttp
      .get("/sessions", {
        params: {
          incId,
          zone,
          sortBy: "startTime:desc",
        },
        pagination: {
          page,
        },
      })
      .then((response) => response.data);

    return {
      sessions: sessions.map((session) => sessionMapper.mapSession(session)),
      pagination,
    };
  },

  async updateSession({ id, result }) {
    await baseHttp.patch(`/sessions/${id}`, { result });
  },
};
