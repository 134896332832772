<template>
  <div class="flex gap-8">
    <Button type="primary" @click="visible = true">
      <PlusOutlined />
      Thêm người dùng
    </Button>
    <Modal
      v-model:visible="visible"
      title="Thêm tài khoản người dùng"
      @ok="addUser"
    >
      <form @submit.prevent="addUser">
        <div class="mb-16">
          Tên đăng nhập:
          <Input v-model="username" />
        </div>
        <div class="mb-16">
          Mật khẩu:
          <Input v-model="password" type="password" />
        </div>
        <div class="mb-16">
          Vai trò:
          <Select v-model="role" :options="ROLES" :style="{ width: '100%' }" />
        </div>
      </form>
    </Modal>
  </div>
</template>

<script setup>
import { message, Modal, Switch } from "ant-design-vue";
import { PlusOutlined } from "@ant-design/icons-vue";
import Input from "@/components/Input.vue";
import Button from "@/components/Button.vue";
import Select from "@/components/Select.vue";
import { ref, watch } from "vue";
import { ROLES } from "@/common";
import useUsers from "@/use/users";
const username = ref();
const password = ref();
const role = ref(null);
const visible = ref(false);

const { createUser, getUsers } = useUsers();
let isRunning = false;
const addUser = async () => {
  if (isRunning) return;
  isRunning = true;
  try {
    await createUser({
      username: username.value,
      password: password.value,
      role: role.value,
    });
    message.success("Tạo người dùng thành công");
    visible.value = false;
    getUsers();
  } catch {
    message.error("Lỗi vui lòng thử lại");
  }
  isRunning = false;
};

watch(visible, () => {
  if (visible.value) {
    username.value = "";
    password.value = "";
    role.value = null;
  }
});
</script>
