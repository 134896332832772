<template>
  <div>
    <Layout style="min-height: 100vh">
      <SideBar
        :style="{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
        }"
      />
      <Layout
        :style="{ marginLeft: isCollapsed ? '65px' : '265px' }"
        class="transistion-sidebar"
      >
        <Header />
        <router-view />
      </Layout>
    </Layout>
  </div>
</template>

<script setup>
import { Layout } from "ant-design-vue";
import SideBar from "@/components/SideBar.vue";
import Header from "@/components/Header.vue";
import useSidebar from "@/use/sidebar";
import useBets from "@/use/bets";

const { getBetConfigs } = useBets();
getBetConfigs();
const { isCollapsed } = useSidebar();
</script>

<style scoped>
.transistion-sidebar {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease;
  transition-delay: 0s;
}
</style>
