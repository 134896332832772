<template>
  <div>
    <h1 class="p-16 text-20 font-bold bg-white mb-16">Danh sách rút tiền</h1>
    <div class="p-32 pt-0">
      <form @submit.prevent class="flex gap-8 flex-wrap">
        <div class="flex gap-4 items-center">
          <span>ID: </span>
          <Input
            v-model="playerIncId"
            type="number"
            :style="{ width: '150px' }"
          />
        </div>
        <!-- <div class="flex gap-4 items-center">
          <span>Trạng thái: </span>
          <Select
            v-model="payload.status"
            :options="statuses"
            :style="{ width: '150px' }"
          />
        </div>
        <div class="flex gap-4 items-center">
          <span>Phương thức: </span>
          <Select
            v-model="payload.type"
            :options="paymentGateTypes"
            :style="{ width: '150px' }"
          />
        </div>
        <div class="flex gap-4 items-center">
          <span>Có báo cáo không: </span>
          <Select
            v-model="payload.hasBill"
            :options="billStatuses"
            :style="{ width: '150px' }"
          />
        </div> -->
        <Button htmlType="submit" type="primary" @click="search">
          <SearchOutlined />
          Tìm kiếm
        </Button>
      </form>
      <WithdrawalTable
        :dataSource="withdrawals"
        class="my-32"
        @mutated="getWithdrawals({ page: currentPage, playerIncId })"
      />
      <div class="mt-16 fixed bottom-16 right-32">
        <Pagination
          v-model:current="currentPage"
          :total="pagination.totalItems"
          :pageSize="pagination.perPage"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import Input from "@/components/Input.vue";
import Select from "@/components/Select.vue";
import Button from "@/components/Button.vue";
import WithdrawalTable from "./components/WithdrawalTable";
import { SearchOutlined } from "@ant-design/icons-vue";
import { ref, watch } from "vue";
import { Pagination, message } from "ant-design-vue";
import useWithdrawals from "@/use/withdrawals";

const { withdrawals, pagination, getWithdrawals } = useWithdrawals();

const currentPage = ref(1);

const playerIncId = ref("");
getWithdrawals({
  page: currentPage.value,
  playerIncId: playerIncId.value,
});

const search = async () => {
  if (currentPage.value !== 1) {
    currentPage.value = 1;
    return;
  }
  try {
    await getWithdrawals({
      page: currentPage.value,
      playerIncId: playerIncId.value,
    });
  } catch {
    message.error("Không tìm thấy ID người chơi", 0.5);
  }
};

watch(currentPage, (newVal) => {
  getWithdrawals({ page: newVal, playerIncId: playerIncId.value });
});
</script>
