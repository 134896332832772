<template>
  <div v-if="$can('edit', 'withdrawals')">
    <Button
      v-show="info.status === PENDING"
      type="primary"
      danger
      @click="showModal(true)"
      class="mr-8"
    >
      Hủy
    </Button>
    <Button @click="showModal(false)">Sửa</Button>
    <Modal
      v-model:visible="visible"
      :title="isCancel ? 'Hủy lệnh rút' : 'Sửa ghi chú'"
      @ok="editWithdrawal"
    >
      <form @submit.prevent="editWithdrawal">
        <div
          v-if="$can('edit', 'playerBank')"
          v-show="info.status === PENDING && !isCancel"
        >
          <div class="mb-16">
            Tên ngân hàng:
            <Input v-model="bankName" />
          </div>
          <div class="mb-16">
            Chủ tài khoản:
            <Input v-model="bankAccountHolder" />
          </div>
          <div class="mb-16">
            Số tài khoản:
            <Input v-model="bankAccountNumber" type="number" />
          </div>
        </div>
        <div class="mb-16">
          Ghi chú:
          <Textarea
            v-model:value="noteContent"
            placeholder="Thêm ghi chú"
            auto-size
            @change="$emit('update:modelValue', noteContent)"
          />
        </div>
      </form>
    </Modal>
  </div>
</template>

<script setup>
import { Textarea, Modal, message } from "ant-design-vue";
import Input from "@/components/Input.vue";
import Button from "@/components/Button.vue";
import { ref, watch } from "vue";
import { PENDING, REJECTED, SALE } from "@/common";
import { useStore } from "vuex";
const store = useStore();
const role = store.getters.role;
import useWithdrawals from "@/use/withdrawals";

const { updateWithdrawal, getWithdrawals, confirmWithdrawal, updateNote } =
  useWithdrawals();

const props = defineProps({
  info: {
    type: Object,
    required: true,
  },
});
const emit = defineEmits(["update:modelValue"]);
const noteContent = ref(props.info.note);
const bankName = ref(props.info.bankName);
const bankAccountHolder = ref(props.info.bankAccountHolder);
const bankAccountNumber = ref(props.info.bankAccountNumber);

const visible = ref(false);
const isCancel = ref(false);

const editWithdrawal = async () => {
  try {
    if (isCancel.value) {
      await confirmWithdrawal({
        id: props.info.id,
        note: noteContent.value || undefined,
        confirm: isCancel.value ? "cancel" : null,
      });
    } else if (role === SALE) {
      await updateNote({
        id: props.info.id,
        note: noteContent.value || undefined,
      });
    } else {
      await updateWithdrawal({
        id: props.info.id,
        note: noteContent.value || undefined,
        bankName: bankName.value,
        bankAccountHolder: bankAccountHolder.value,
        bankAccountNumber: bankAccountNumber.value,
      });
    }
    message.success("Cập nhật thành công");
    getWithdrawals();
    visible.value = false;
  } catch (e) {
    console.error(e);
    message.error("Lỗi vui lòng thử lại");
  }
};

watch(visible, () => {
  if (visible.value) {
    noteContent.value = props.info.note;
  }
});

const showModal = (value) => {
  isCancel.value = value;
  visible.value = true;
};
</script>
