import { format } from "date-fns";
export const mapDeposit = ({
  id,
  amount,
  hasBill,
  status,
  player,
  createdAt,
  note,
}) => {
  return {
    id,
    amount,
    hasBill,
    status,
    playerIncId: player.incId,
    time: format(new Date(createdAt), "hh:mm dd/MM/yyyy"),
    note,
  };
};
