export const columns = [
  {
    title: "ID người chơi",
    key: "playerIncId",
    slots: { customRender: "playerIncId" },
    align: "center",
  },
  {
    title: "Giá trị",
    key: "amount",
    slots: { customRender: "amount" },
  },
  {
    title: "Trạng thái",
    key: "status",
    slots: { customRender: "status" },
  },
  {
    title: "Ngân hàng",
    key: "bankName",
    dataIndex: "bankName",
  },
  {
    title: "Số tài khoản",
    key: "bankAccountNumber",
    slots: {
      customRender: "bankAccountNumber",
    },
  },
  {
    title: "Chủ tài khoản",
    key: "bankAccountHolder",
    dataIndex: "bankAccountHolder",
  },
  {
    title: "Thời gian",
    key: "time",
    dataIndex: "time",
  },
  {
    title: "Ghi chú",
    key: "note",
    slots: { customRender: "note" },
  },
  {
    title: "",
    key: "action",
    slots: {
      customRender: "action",
    },
  },
];
