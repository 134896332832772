<template>
  <div>
    <Button @click="visible = true" danger>Thu hồi</Button>
    <Modal
      v-model:visible="visible"
      title="Sửa tài khoản người chơi"
      @ok="editBalance"
    >
      <form @submit.prevent="editBalance">
        <div class="mb-16">Số dư hiện tại: {{ info.balance }}</div>
        <div class="mb-16">Tổng nạp: {{ info.totalDeposit }}</div>
        <div>
          Thu hồi:
          <Input v-model="recallAmount" type="number" />
        </div>
      </form>
    </Modal>
  </div>
</template>

<script setup>
import { Modal, Switch, message } from "ant-design-vue";
import Input from "@/components/Input.vue";
import Button from "@/components/Button.vue";
import Select from "@/components/Select.vue";
import { ref, watch } from "vue";
import usePlayers from "@/use/players";
const props = defineProps({
  info: {
    type: Object,
    required: true,
  },
});
const { recallBalance, getPlayers } = usePlayers();
const recallAmount = ref();

const visible = ref(props.modelValue);
let isRunning = false;
const editBalance = async () => {
  if (isRunning) return;
  isRunning = true;
  try {
    await recallBalance({
      id: props.info.id,
      amount: recallAmount.value,
    });
    visible.value = false;
    message.success("Cập nhật thành công");
    getPlayers();
  } catch (e) {
    console.error(e);
    message.error("Lỗi, vui lòng thử lại");
  }
  isRunning = false;
};

watch(visible, () => {
  if (visible.value) {
    recallAmount.value = "";
  }
});
</script>
