import { SET_ACCOUNT_INFO } from "../mutations.type";
import { setAbilitiesFor } from "@/helpers/abilityHelper";

export default function abilityPlugin(store) {
  return store.subscribe((mutation) => {
    switch (mutation.type) {
      case SET_ACCOUNT_INFO:
        setAbilitiesFor(mutation.payload);
        break;
    }
  });
}
