import { format } from "date-fns";

export const mapWithdrawal = ({
  id,
  player,
  amount,
  status,
  bankAccountNumber,
  bankAccountHolder,
  createdAt,
  bankName,
  note,
}) => {
  return {
    id,
    playerIncId: player.incId,
    amount,
    status,
    bankAccountNumber,
    bankAccountHolder,
    time: format(new Date(createdAt), "hh:mm dd/MM/yyyy"),
    bankName,
    type: "WITHDRAWAL",
    note,
  };
};
